import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import {
  FilterKeys,
  GuestAgentUpdateReq,
  LanguageList,
  QueueCall,
  QueueCallsAndLists
} from '../../modals/queue.config';
import { Constants } from '../../../../../core/constants/constants';
import { catchError, map } from 'rxjs/operators';
import { SaveQueueListRes } from '../../../../../core/constants/video-config';
import { HttpWrapperService } from '../../../../../core/services/http-service/http-wrapper.service';
import { CommonRes } from '../../../../../core/constants/common.enum';

@Injectable({
  providedIn: 'root'
})
export class QueueService {
  public clearFilter$ = new Subject<boolean>();
  public filterOptions$ = new Subject<FilterKeys>();
  public callsFromCard$ = new Subject<QueueCall>();
  public flashWaitingBar$ = new Subject<boolean>();
  public playSound$ = new Subject<boolean>();
  public setAgentFilterValue$ = new Subject<string[]>();

  constructor(private httpClientWrapper: HttpWrapperService) {}

  getQueueList(): Observable<QueueCallsAndLists | any> {
    return this.httpClientWrapper
      .post('sample', `${Constants.apiPaths.getQueueList}`, {})
      .pipe(
        map((res: SaveQueueListRes) => {
          return res.data;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  updateQueuePosition(req, positionId) {
    if (positionId) {
      return this.httpClientWrapper
        .post('', `${Constants.apiPaths.queueUpdate}/${positionId}`, req)
        .pipe(
          map((res: CommonRes) => {
            return res;
          })
        )
        .pipe(
          catchError((e) => {
            return throwError(e);
          })
        );
    } else {
      console.log('queue position id is missing', positionId);
    }
  }

  guestAgentUpdate(req: GuestAgentUpdateReq) {
    return this.httpClientWrapper
      .post('', `${Constants.apiPaths.guestAgentUpdate}`, req)
      .pipe(
        map((res: CommonRes) => {
          return res;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  getLanguageList() {
    return this.httpClientWrapper
      .post('', `${Constants.apiPaths.getLanguages}`, {})
      .pipe(
        map((res: { languages: LanguageList[] }) => {
          return res.languages;
        })
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        })
      );
  }

  covertSocketQueueDataToQueueCallFormat(data: QueueCall): QueueCall {
    return {
      queue_id: +data?.queue_id,
      queue_position_id: +data?.queue_position_id,
      call_config: data?.call_config,
      guest_user_id: data?.guest_user_id,
      assigned_agent: data?.assigned_agent,
      assigned_manually: data?.assigned_manually,
      assigned_agent_name: data?.assigned_agent_name,
      connected_participants: data?.connected_participants,
      customer_contact: data?.customer_contact,
      call_type: data?.call_type,
      room_name: data?.room_name,
      language: data?.language,
      logo: data?.logo,
      industry: data?.industry,
      comment: data?.comment,
      call_notes: data?.call_notes,
      scheduled_datetime: data?.scheduled_datetime,
      duration: data?.duration,
      created: data?.created,
      started: data?.started,
      status: data?.status,
      tags: data?.tags,
      last_message: data?.last_message,
      full_name: data?.full_name,
      email: data.email,
      phone: data?.phone,
      location: data?.location,
      custom_fields: data?.custom_fields,
      session_data: data?.session_data,
      channel: data?.channel,
      location_config: data?.location_config,
      previous_conversations: data?.previous_conversations,
      vehicle_history: data?.vehicle_history,
      inventory: data?.inventory,
      tenant_id: data?.tenant_id,
      booking_config: data?.booking_config,
      lam_lead_type: data?.lam_lead_type,
      invited_agents: data?.invited_agents,
      call_summary: data?.call_summary,
      kbsearch: data?.kbsearch,
      licensekey: data?.licensekey,
      lead_type: data?.lead_type
    };
  }
}
