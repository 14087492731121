import {
  Agent,
  CallConfig,
  SaveQueueListAttr
} from '../../../../core/constants/video-config';
import { Message } from '../../../../core/constants/chat-config';
import { OutboundData } from '../../../../core/constants/outbound-config';
import { Participant } from '../modules/call/modals/call-config';
import { BookingConfig } from '../../../../dashboard/settings/settings-constants/types-config/types';

export interface QueueListRes {
  status: number;
  data: QueueCall[];
}
export interface QueueCall {
  call_config: CallConfig;
  call_type: string;
  call_notes: string;
  call_summary?: string;
  connected_participants: CallParticipants[];
  customer_contact?: CustomerContact;
  queue_position_id: number;
  guest_user_id: string;
  created: number;
  status: string;
  full_name: string;
  email: string;
  phone: string;
  assigned_agent: string;
  assigned_agent_name: string;
  comment: string;
  scheduled_datetime: string;
  textback_requested?: number;
  room_name: string;
  room_sid?: string;
  assigned_manually?: null | boolean;
  products_services?: string;
  queue_id: number;
  date?: string;
  waitingTime?: string;
  custom_fields?: {};
  duration?: number;
  queue_name?: string;
  scheduledEndTime?: string;
  tenantId?: number;
  tags: string[];
  started?: number;
  messageList?: Message[];
  participantList?: Participant[];
  location: string;
  callQueueId?: number;
  videoEnabled?: boolean;
  smallRoomVideoEnabled?: boolean;
  observeEnabled?: boolean;
  outboundCall?: boolean;
  cobrowseEnabled?: boolean;
  session_data: OutboundData;
  isAgentOnCall?: boolean;
  isGuestOnCall?: boolean;
  smallRoomAvailable?: boolean;
  inSmallRoom?: boolean;
  smallRoomName?: string;
  smallRoomParticipantList?: Participant[];
  smallRoomMessageList?: Message[];
  updatedScheduleTime?: string;
  updatedCreateTime?: string;
  queueName?: string;
  agentDetail?: Agent;
  updatedFormatDate?: string;
  updateCreatedTimeFormat?: string;
  snowPlowUserIdForFirstGuest?: string;
  channel?: string;
  tenant_id?: number[];
  language?: string;
  logo?: string;
  industry?: string;
  last_message?: string;
  isGuestUserIdForChannel?: boolean;
  observeEnabledValues?: any;
  observeActiveGuestId?: any;
  action?: string;
  typingMessage?: string;
  isCallStart?: boolean;
  isVideoEnabled?: boolean;
  isSmallRoomVideoEnabled?: boolean;
  isObserveEnabled?: boolean;
  isOutboundCall?: boolean;
  isVideoCall?: boolean;
  isAudioCall?: boolean;
  latestMessage?: string;
  unreadMessage?: boolean;
  location_config?: LocationConfig;
  latestMsgTime?: string;
  previousRoomNameIndex?: number;
  nextPageToken?: string;
  previous_conversations?: string[];
  isScrolled?: boolean;
  vehicle_history?: VehicleInfo[];
  inventory?: VehicleInfo[];
  booking_config?: BookingConfig;
  lam_lead_type?: string;
  invited_agents?: InvitedGuest[];
  kbsearch?: number;
  licensekey?: string;
  lead_type?: string;
}

export interface CustomerContact {
  full_name: string;
  phone: string;
  email: string;
  cell_phone?: string;
  first_name?: string;
  last_name?: string;
}

export interface InvitedGuest {
  agent_id: number;
  agent_name: string;
  agent_avatar: string;
  status: string;
}

export enum InvitedGuestStatus {
  joined = 'Joined',
  declined = 'Declined',
  accepted = 'Accepted',
  noReply = 'No Reply'
}

export interface VehicleInfo {
  status?: string;
  year?: number | string;
  make?: string;
  model?: string;
  trim?: string;
  vin?: number | string;
  stock_number?: number | string;
  interior_color?: string;
  exterior_color?: string;
  dealer_name?: string;
  licenseid?: string | number;
  dealer_address?: string;
  dealer_city?: string;
  dealer_region?: string;
  dealer_postal?: string;
  stock_status?: string;
}

export interface LocationConfig {
  client_timezone: string;
  url: string;
  address?: AddressLocationConfig;
  agent_availability: AgentAvailability[];
  hours: AgentAvailability[];
  contact: ClientContact[];
  notes?: string;
}

export interface ClientContact {
  department: string;
  phone_number: string;
}

export interface AgentAvailability {
  department: string;
  monday?: AgentDaysAvailability[];
  tuesday?: AgentDaysAvailability[];
  wednesday?: AgentDaysAvailability[];
  thursday?: AgentDaysAvailability[];
  friday?: AgentDaysAvailability[];
  saturday?: AgentDaysAvailability[];
  sunday?: AgentDaysAvailability[];
  exceptions?: Exceptions;
}

export interface Exceptions {
  [key: string]: AgentDaysAvailability[];
}
export interface AgentDaysAvailability {
  start?: string;
  end?: string;
  agents?: number[];
}
export interface AddressLocationConfig {
  address_1: string;
  address_2?: string;
  city: string;
  province: string;
  postal_code: string;
}

export enum QueueStatus {
  pending = 'Pending',
  inProgress = 'In Progress',
  dismissed = 'Dismissed',
  closed = 'Closed',
  Cancelled = 'Cancelled',
  scheduled = 'Scheduled',
  waiting = 'Waiting',
  missed = 'Missed'
}

export const QueueStatusWithLabel = {
  Pending: 'pending',
  'In Progress': 'inProgress',
  Dismissed: 'dismissed',
  Closed: 'closed',
  Cancelled: 'cancelled',
  Scheduled: 'scheduled',
  Waiting: 'waiting',
  Missed: 'missed'
};

export interface QueueList {
  queue_id: number;
  tenantid: number;
  queue_name: string;
  status: string;
  attr: null | SaveQueueListAttr;
  calls_in_queue: number;
  access?: boolean;
  booking_config?: BookingConfig;
}

export interface QueueCallsAndLists {
  queue_calls: QueueCalls;
  queue_list: QueueList[];
  inventory: InventoryList;
}

export interface InventoryList {
  [key: string]: VehicleInfo[];
}

export interface QueueCalls {
  scheduled: QueueCall[];
  pending: QueueCall[];
  in_progress: QueueCall[];
}

export interface FilterKeys {
  queueFilter: number[];
  agentFilter: number[];
}

export interface CallParticipants {
  avatar: string;
  first_name: string;
  last_name: string;
  user_type: string;
  shortName?: string;
  status?: string;
  user_id: number;
  full_name?: string;
  email?: string;
  phone?: string | number;
  location?: string;
  omnichannel_address?: string;
  is_connected?: number;
}

export const ColorSet = [
  {
    bgColor: '#ff9a1e',
    textColor: '#ffffff'
  },
  {
    bgColor: '#00a5be',
    textColor: '#ffffff'
  },
  {
    bgColor: '#0352cc',
    textColor: '#ffffff'
  },
  {
    bgColor: '#df360e',
    textColor: '#ffffff'
  },
  {
    bgColor: '#5345ab',
    textColor: '#ffffff'
  },
  {
    bgColor: '#ffeb3b',
    textColor: '#000000'
  },
  {
    bgColor: '#4caf50',
    textColor: '#000000'
  },
  {
    bgColor: '#03a9f4',
    textColor: '#000000'
  },
  {
    bgColor: '#795548',
    textColor: '#ffffff'
  },
  {
    bgColor: '#607d8b',
    textColor: '#ffffff'
  }
];

export const GuestsColorSets = [
  {
    bgColor: '#1497db',
    textColor: '#ffffff'
  },
  {
    bgColor: '#746ef4',
    textColor: '#ffffff'
  },
  {
    bgColor: '#6eadf4',
    textColor: '#ffffff'
  },
  {
    bgColor: '#305D19',
    textColor: '#000000'
  },
  {
    bgColor: '#607d8b',
    textColor: '#ffffff'
  }
];

export const AgentsColorSets = [
  {
    bgColor: '#58d0c6',
    textColor: '#ffffff'
  },
  {
    bgColor: '#ebc480',
    textColor: '#ffffff'
  },
  {
    bgColor: '#e27aba',
    textColor: '#ffffff'
  },
  {
    bgColor: '#2B3E49',
    textColor: '#000000'
  },
  {
    bgColor: '#795548',
    textColor: '#ffffff'
  }
];

export interface GuestAgentUpdateReq {
  guest_id: number;
  full_name?: string;
  phone?: string;
  email?: string;
  location?: string;
  room_name: string;
}

export interface QueueUpdateReq {
  status?: string;
  attr_update?: string;
  queue_id?: number;
}

export enum GeneralQueueData {
  callQueueId = 'callQueueId',
  filterData = 'filterData',
  routeParamsAction = 'routeParamsAction'
}

export enum AgentNotificationData {
  notificationConfig = 'notificationConfig',
  online = 'online',
  offline = 'offline'
}

export enum LeadDetails {
  leadFormInfo = 'leadFormInfo',
  userEnterValues = 'userEnterValues',
  isFormValid = 'isFormValid',
  isFormErrorShow = 'isFormErrorShow',
  isPhoneValid = 'isPhoneValid',
  guestId = 'guest_id',
  licenseId = 'licenseId'
}

export interface GeneralStatus {
  callIndex?: number;
}

export enum CallKeys {
  audioInDevice = 'audioInDevice',
  audioOutDevice = 'audioOutDevice',
  audioOutDeviceId = 'audioOutDeviceId',
  camEnabledOnce = 'camEnabledOnce',
  camOff = 'camOff',
  cobrowseEnabled = 'cobrowseEnabled',
  inSmallRoom = 'inSmallRoom',
  inviteCode = 'inviteCode',
  invitedAgents = 'invited_agents',
  isAgentOnCall = 'isAgentOnCall',
  isGuestOnCall = 'isGuestOnCall',
  lastParticipantInFull = 'lastParticipantInFull',
  messageList = 'messageList',
  micEnabledOnce = 'micEnabledOnce',
  micOff = 'micOff',
  observeEnabled = 'observeEnabled',
  outboundCall = 'outboundCall',
  participantList = 'participantList',
  smallRoomAvailable = 'smallRoomAvailable',
  smallRoomMessageList = 'smallRoomMessageList',
  smallRoomParticipantList = 'smallRoomParticipantList',
  smallRoomName = 'smallRoomName',
  showChat = 'showChat',
  videoEnabled = 'videoEnabled',
  smallRoomVideoEnabled = 'smallRoomVideoEnabled',
  videoInDevice = 'videoInDevice',
  fullName = 'full_name',
  email = 'email',
  phone = 'phone',
  location = 'location',
  observeEnabledValues = 'observeEnabledValues',
  typingMessage = 'typingMessage',
  unreadMessage = 'unreadMessage',
  nextPageToken = 'nextPageToken',
  previousRoomNameIndex = 'previousRoomNameIndex',
  isScrolled = 'isScrolled',
  kbRequestDetails = 'kbRequestDetails',
  translateLang = 'translateLang',
  translateLangTo = 'translateLangTo',
  isFromInviteLink = 'isFromInviteLink',
  shownInvite = 'shownInvite',
  channel = 'channel'
}

export interface CallStatus {
  audioInDevice?: string;
  audioOutDevice?: string;
  audioOutDeviceId?: string;
  camEnabledOnce?: boolean;
  cobrowseEnabled?: boolean;
  inMainRoom?: boolean;
  inviteCode?: string;
  isCamOff?: boolean;
  isMicOff?: boolean;
  lastParticipantInFull?: string;
  messageList?: string;
  micEnabledOnce?: boolean;
  observeEnabled?: boolean;
  outboundCall?: boolean;
  participantList?: string;
  videoEnabled?: boolean;
  videoInDevice?: string;
}

export interface DeviceList {
  deviceId: string;
  groupId: string;
  kind: string;
  label: string;
}

export enum DeviceTypes {
  audioInput = 'audioinput',
  audioOutput = 'audiooutput',
  videoInput = 'videoinput'
}

export enum IgnoreDeviceIdTypes {
  default = 'default',
  communications = 'communications'
}

export enum ChannelTypes {
  sms = 'sms',
  fbmessenger = 'fbmessenger',
  googlebm = 'googlebm',
  lam = 'LAM'
}

export interface LanguageList {
  code: string;
  name: string;
}

export interface LeadGuestDetails {
  guestInfo?: CallParticipants;
  queue_name: string;
  queue_position_id?: number;
  roomName: string;
  industry?: string;
  booking_config?: BookingConfig;
  customer_contact?: CustomerContact;
  comment?: string;
  vehicle_history?: VehicleInfo[];
  lead_type?: string;
}

export enum CallActions {
  callEnded = 'callEnded',
  feedbackSubmitted = 'feedbackSubmitted',
  feedbackCancelled = 'feedbackCancelled'
}
